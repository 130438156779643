.source p {
	font-size: x-small;
}

.source p::before {
	content: 'Source: ';
}

.popup {
	position: absolute;
	z-index: -1;
	border: 0;
	padding: 0;
	margin: 0;
	bottom: 0;
	animation-name: moveup;
	animation-duration: 1.2s;
	animation-timing-function: ease-in;
	animation-fill-mode: forwards;
}

.popup div {
	float: right;
}

.popup img {
	min-width: 15vmax;
	width: 25vmin;
	max-width: 400px;
	animation-name: wobble;
	animation-duration: 1.2s;
	animation-timing-function: ease-in;
	animation-fill-mode: forwards;
}

@keyframes moveup {
	from {
		bottom: -100%;
	}
	/* delay animation a little */
	20% {
		bottom: -100%;
	}
	to {
		bottom: 0;
	}
}

@keyframes wobble {
	from {
		transform: rotate(50deg);
	}
	20% {
		transform: rotate(50deg) translateZ(0);
	}
	to {
		transform: rotate(0deg) translateZ(0);
	}
}
